<script>
import size from 'lodash/size';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';

import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';

import {
  addListener as addScrollEventListener,
  nodeElements as locationsVisibility,
  removeListener as removeScrollEventListener,
} from '@/utils/visibleOnViewport';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';

import { isPreregistered } from '@/helpers/user/status/userStatusHelper';

import { useAvailability } from '@/composable/Availability/useAvailability';
import { genericUserBlockArgs } from '@/constants/defaultModalArgs';

import { USER_STATUS } from '@/constants/userStatus.const';
import { getCurrentCity } from '@/stores/City/CityMapper';
import { getCurrentOperatorCurrency } from '@/stores/CSOperator/CSOperatorMapper';
import { emptyLocation, tariffExpiredEmptyState } from '@/utils/publicImages';
import { mapGetters, mapState } from 'vuex';
import { nameSpace as ACCOUNT_MODULE_NAMESPACE } from '@/vue/stores/UserData/UserDataStore';
import { nameSpace as ProfileNameSpace } from '@Profile/store';
import { USER_ROLES } from '@/constants/user.const';
import Location from './Location';
import LocationPlaceholder from '../LocationPlaceholder/LocationPlaceholder';

export default {
  components: {
    EmptyStateComponent,
    Location,
    LocationPlaceholder,
    FeedbackModalComponent,
  },

  props: {
    mapManager: {
      type: Object,
      required: true,
    },
    selectedLocation: {
      type: String,
      default: null,
    },
    datetimepickerManager: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { getLocationsAvailables, availabilityRange, getSelectedEmployee } = useAvailability();
    return { locations: getLocationsAvailables, availabilityRange, getSelectedEmployee };
  },
  data() {
    return {
      areBookButtonsLoading: false,
      locationsVisibility,
      modals: {
        bookingOnBehalfBlock: {
          ...genericUserBlockArgs(this.$t),
          isOpen: false,
          title: this.$t('new_booking.booking_list.booking_on_behalf.employee_has_invalid_documents.modal.title'),
          description: this.$t('new_booking.booking_list.booking_on_behalf.employee_has_invalid_documents.modal.content'),
          primaryCallToActionText: this.$t('buttons.close'),
          primaryCallToAction: () => {
            this.modals.bookingOnBehalfBlock.isOpen = false;
          },
          testIdSuffix: 'booking_on_behalf_error',
        },
      },
    };
  },
  computed: {
    ...mapState(ACCOUNT_MODULE_NAMESPACE, {
      userData: state => get(state, 'userData'),
    }),
    ...mapGetters(ProfileNameSpace, {
      currentProfile: 'getCurrentProfile',
      isBusinessProfileActive: 'isBusinessProfileActive',
    }),
    getCurrentCity,
    getCurrentOperatorCurrency,
    isCompanyWithoutTariff() {
      const companyData = this.currentProfile.getCompanyData();
      return companyData && !companyData.getCompanyTariff();
    },
    emptyStateImage() {
      return this.shouldShowDefaultEmployeeEmptyState ? tariffExpiredEmptyState : emptyLocation;
    },
    emptyStateTitle() {
      return this.shouldShowDefaultEmployeeEmptyState ? this.$t('modal.tariff.road_block_business.title') : this.$t('views.booking.empty_location_list.title');
    },
    shouldShowDefaultEmployeeEmptyState() {
      return this.isBusinessProfileActive && this.isCompanyWithoutTariff && !get(this.userData, 'roles', []).includes(USER_ROLES.companyAdmin);
    },
    bookingPeriod() {
      const { start, end } = this.datetimepickerManager;
      return start && end
        ? { start, end }
        : null;
    },
  },
  mounted() {
    addScrollEventListener();
  },
  beforeDestroy() {
    removeScrollEventListener();
  },
  methods: {
    size,
    get,
    snakeCase,

    bookingOnBehalfMoreInfo() {
      const employeeSelectedStatus = get(this.getSelectedEmployee, 'user_status', USER_STATUS.deactivated);
      const error = {
        message: isPreregistered(employeeSelectedStatus)
          ? this.$t('new_booking.booking_list.booking_on_behalf.employee_is_pre_registered.block_modal.content')
          : this.$t('new_booking.booking_list.booking_on_behalf.employee_has_invalid_documents.modal.content'),
        subParagraph: isPreregistered(employeeSelectedStatus)
          ? this.$t('new_booking.booking_list.booking_on_behalf.employee_is_pre_registered.block_modal.content_sub_paragraph')
          : this.$t('new_booking.booking_list.booking_on_behalf.employee_has_invalid_documents.modal.content_sub_paragraph'),
      };

      this.modals.bookingOnBehalfBlock.description = `${error.message}<br/><br/>${error.subParagraph}`;

      this.modals.bookingOnBehalfBlock.isOpen = true;
    },
  },
};
</script>
<template>
  <div
    data-test-id="booking-list_list_locations-list"
    :class="[
      'MapViewLayout_vehicleList',
    ]"
  >
    <template v-if="locations">
      <template v-for="location in locations">
        <Location
          :key="get(location, 'uuid')"
          :availability-time="availabilityRange"
          :booking-period="bookingPeriod"
          :location="location"
          :locations-visibility="locationsVisibility"
          :map-manager="mapManager"
          :city="get(getCurrentCity, 'name')"
          :currency="getCurrentOperatorCurrency"
          :are-book-buttons-loading="areBookButtonsLoading"
          class="px-md-4"
          @on:booking-on-behalf-roadblock="bookingOnBehalfMoreInfo"
          @update:book-buttons-loading="value => areBookButtonsLoading = value"
        />
      </template>
      <FeedbackModalComponent
        v-model="modals.bookingOnBehalfBlock.isOpen"
        v-bind="modals.bookingOnBehalfBlock"
      />
      <template v-if="!size(locations)">
        <div class="p-5">
          <EmptyStateComponent
            :src="emptyStateImage"
            :title="emptyStateTitle"
            has-background
          >
            <template #description>
              <div v-if="shouldShowDefaultEmployeeEmptyState">
                {{ $t('modal.tariff.road_block_business.text') }}
              </div>
              <div
                v-else
                class="text-left"
              >
                <ol
                  class="d-inline-block"
                  style="list-style: disc inside;"
                >
                  <li
                    v-for="translation in $t('views.booking.empty_location_list.list_options')"
                    :key="snakeCase(translation)"
                  >
                    {{ translation }}
                  </li>
                </ol>
                <p>{{ $t('views.booking.empty_location_list.info_text_not_availability') }}</p>
              </div>
            </template>
          </EmptyStateComponent>
        </div>
      </template>
    </template>
    <template v-else>
      <LocationPlaceholder class="px-md-4" />
    </template>
  </div>
</template>
